// Footer.js
import React from 'react';

function Footer() {
  return (
    <footer className="footer bg-dark text-white text-center py-3">
      <div className="container">
        <span>&copy; 2024 RADSO Limited. All rights reserved.</span>
      </div>
    </footer>
  );
}

export default Footer;
