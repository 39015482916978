import React from 'react';

function Banner() {
  return (
    <div className="bg-primary text-white text-center p-4">
      <h1>RADSO Limited</h1>
      <div className="input-group mb-3">
        <input type="text" className="form-control" placeholder="Search products..." />
        <div className="input-group-append">
          <button className="btn btn-outline-secondary" type="button">Search</button>
        </div>
      </div>
      <button className="btn btn-success">Login/Register</button>
      <span className="ml-3"><i className="fas fa-shopping-cart"></i> <strong>Cart</strong></span>
    </div>
  );
}

export default Banner;
