import React from 'react';
import Banner from './components/Banner';
import Navigation from './components/Navigation';
import Slider from './components/Slider';
import ImageList from './components/ImageList';
import Footer from './components/Footer';

function App() {
  return (
    <div>
      <Banner />
      <Navigation />
      <Slider />
      <ImageList />
      <Footer />
    </div>
  );
}

export default App;
