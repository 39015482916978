// Navigation.js
import React from 'react';

function Navigation() {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="collapse navbar-collapse justify-content-center">
        <ul className="navbar-nav">
          <li className="nav-item"><a className="nav-link" href="#">Fasteners</a></li>
          <li className="nav-item"><a className="nav-link" href="#">Screws</a></li>
          <li className="nav-item"><a className="nav-link" href="#">Fixings</a></li>
          <li className="nav-item"><a className="nav-link" href="#">Nails</a></li>
          <li className="nav-item"><a className="nav-link" href="#">Powertool Accessories</a></li>
          <li className="nav-item"><a className="nav-link" href="#">Electrical</a></li>
          <li className="nav-item"><a className="nav-link" href="#">Hardware</a></li>
          <li className="nav-item"><a className="nav-link" href="#">Tools</a></li>
          <li className="nav-item"><a className="nav-link" href="#">Others</a></li>
        </ul>
      </div>
    </nav>
  );
}

export default Navigation;
